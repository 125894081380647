import React from "react";
import "./Footer.css";
import "../../layout.css";

export default function Footer() {
  return (
    <div className="footer container">
      <div className="footer-content">
        <img
          className="footer-logo"
          src="darwins-logo-sm.png"
          alt="logo"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
        <div className="icons">
          <a
            className="instagram"
            href="https://www.instagram.com/darwins.io/"
             target="_blank" rel="noreferrer" 
          >
            <svg>
              <use href="icons.svg#instagram"></use>
            </svg>
          </a>
          <a
            className="linkedin"
            href="https://www.linkedin.com/company/darwins-io"
             target="_blank" rel="noreferrer" 
          >
            <svg>
              <use href="icons.svg#linkedin"></use>
            </svg>
          </a>
          <a
            className="twitter"
            href="https://twitter.com/darwins_io"
             target="_blank" rel="noreferrer" 
          >
            <svg>
              <use href="icons.svg#twitter"></use>
            </svg>
          </a>
          <a
            className="medium"
            href="https://medium.com/darwins-io"
             target="_blank" rel="noreferrer" 
          >
            <svg>
              <use href="icons.svg#medium"></use>
            </svg>
          </a>
        </div>
      </div>
      <div className="footer-text">
        <p className="copyright">
          © DARWINS INC. ALL RIGHTS RESERVED. |{" "}
          <a href="/privacy-policy">PRIVACY POLICY</a> |{" "}
          <a href="/terms-of-service">TERMS OF SERVICE</a>
        </p>
        <p className="address">5151 CALIFORNIA AVENUE IRVINE CA 92617</p>
      </div>
    </div>
  );
}
