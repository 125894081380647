import React from "react";
import "./Main.css";
import "../../layout.css";
import "animate.css";

export default function Main() {
  return (
    <div className="main container">
      <div className="main-content container">
        <img
          className="animate__animated animate__fadeIn main-content-img"
          src="darwins-logo-lg.png"
          alt="logo"
        />
        <div className="main-content-line animate__animated animate__fadeIn"></div>
        <div className="main-content-text container animate__animated animate__fadeInDown">
          <h3>Practical Web 3.0</h3>
          <p>
            Darwins builds applications connecting{" "}
            <span style={{ color: "#F065F6" }}>people</span> to{" "}
            <span style={{ color: "#3240AD" }}>Web3</span>
          </p>
          <div className="icons">
            <a
              className="instagram"
              href="https://www.instagram.com/darwins.io/"
               target="_blank" rel="noreferrer" 
            >
              <svg>
                <use href="icons.svg#instagram"></use>
              </svg>
            </a>
            <a
              className="linkedin"
              href="https://www.linkedin.com/company/darwins-io"
               target="_blank" rel="noreferrer" 
            >
              <svg>
                <use href="icons.svg#linkedin"></use>
              </svg>
            </a>
            <a
              className="twitter"
              href="https://twitter.com/darwins_io"
               target="_blank" rel="noreferrer" 
            >
              <svg>
                <use href="icons.svg#twitter"></use>
              </svg>
            </a>
            <a
              className="medium"
              href="https://medium.com/darwins-io"
               target="_blank" rel="noreferrer" 
            >
              <svg>
                <use href="icons.svg#medium"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
