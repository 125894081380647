import React from "react";
import "./Team.css";
import "../../layout.css";
import "animate.css";

const team = require("./team.json");

export default function Team() {
  return (
    <div className="team container">
      <h2>Team</h2>
      <div className="members">
        {team.map((member) => (
          <div className="member" key={member.NAME}>
            <div className="member-profile-picture">
              <img src={member.IMAGE} alt={member.NAME} />
            </div>
            <h3 className="member-name">{member.NAME}</h3>
            <p className="member-position">{member.POSITION}</p>
              <a className="linkedin" href={member.LINKEDIN}  target="_blank" rel="noreferrer" >
                <svg>
                  <use href="icons.svg#linkedin"></use>
                </svg>
              </a>
          </div>
        ))}
      </div>
    </div>
  );
}
