import React from "react";
import "./About.css";
import "../../layout.css";

export default function About() {
  return (
    <div className="about container">
      <div className="about-content container">
        {/* <div className="about-content-text container">
          <h4>PIPELINE</h4>
          <h3>Projects</h3>
          <p>Take a look at what we're working on.</p>
            <div className="about-content-text-links">
              <a href="https://burnclub.app" target="_blank" rel="noreferrer" class="img-link">
                  <img className="about-content-img" src="burnclub-logo.png" alt="logo" />
              </a>
              <a href="https://cofi.app" target="_blank" rel="noreferrer" class="img-link">
                <img className="about-content-img cofi" src="cofi-logo.png" alt="logo" />
              </a>
            </div>
        </div> */}
        {/* <div className="about-content-line"></div> */}
        <div className="about-content-text container">
          <h4>CALLING ALL BUILDERS</h4>
          <h3>Darwins is Hiring</h3>
          <p>We're looking for talented team members to join us.</p>
          <a href="/careers" className="openings true-center">
            <svg>
              <use href="icons.svg#folder"></use>
            </svg>
            <span>See openings</span>
          </a>
        </div>
      </div>
    </div>
  );
}
