import React from "react";
import "./Banner.css";
import "../../layout.css";

export default function Banner() {
  return (
    <div className="banner container true-center">
      <div className="banner-inner container true-center">
        <h2>Build the Decentralized Web 🏗️</h2>
        <p>
          Web 3.0 is here and ready for real people. We bridge the gap between
          the crypto enthusiast and the everyperson.
        </p>
      </div>
    </div>
  );
}
