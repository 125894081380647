import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import Main from "./components/Main/Main.jsx";
import Banner from "./components/Banner/Banner.jsx";
import About from "./components/About/About.jsx";
import Team from "./components/Team/Team.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Careers from "./components/Careers/Careers.jsx";
import Privacy from "./components/Privacy/Privacy.jsx"
import TOS from "./components/TOS/TOS.jsx";

import ReactGA from "react-ga"
import TagManager from "react-gtm-module"

ReactGA.initialize('UA-146770899-1')

const tagManagerArgs = {
  gtmId: 'GTM-KT9CWGC'
}

TagManager.initialize(tagManagerArgs)

export default function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  })
  
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Main />
          <Banner />
          <About />
          <Team />
          <Footer />
        </Route>
        <Route path="/careers">
          <Careers />
        </Route>
        <Route path="/privacy-policy">
          <Privacy />
        </Route>
        <Route path="/terms-of-service">
          <TOS />
        </Route>
      </Switch>
    </Router>
  );
}
